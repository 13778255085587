.tooltip {
  position: absolute;
  z-index: 10;
  visibility: hidden;
  background-color: #ffffff;
  text-align: center;
  padding: 4px;
  border-radius: 7px;
  border: solid #000;
  border-width: 1px;
  color: #000;
  padding: 10px;
  white-space: pre-wrap;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  width: auto;
  max-width: 320px;
}