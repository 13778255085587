@import '../../_css//globals.scss';

.svgsimmutron {
    width: 100%;
    height: 100%;
    background-color: $themeprimary;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
}

.content-container {
    z-index: 100;
    position: relative;
}

.logo-container {
    margin-bottom: 2.5rem;
    text-align: center;
}

.tagline {
    text-align: center;
    margin-bottom: 2.5rem;
    z-index: 100;
    max-width: 90%;
}

.loginContainer {
    z-index: 100;
    text-align: center;
}

.loginButton {
    min-width: 180px;
    transition: all 0.3s ease;
}

// Animations for the logo and content
@keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
}

.logo-container {
    animation: fadeIn 1s ease-out;
}

.tagline {
    animation: fadeIn 1s ease-out 0.3s forwards;
    opacity: 0;
    animation-fill-mode: forwards;
}

.loginContainer {
    animation: fadeIn 1s ease-out 0.6s forwards;
    opacity: 0;
    animation-fill-mode: forwards;
}

// Media queries for responsive design
@media (max-width: 600px) {
    .logo-container {
        margin-bottom: 1.5rem;
        
        img {
            max-width: 220px;
        }
    }
    
    .tagline {
        margin-bottom: 1.5rem;
    }
}

// Keep necessary legacy styles
.wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
}

.biglogo {
    width: 80%;
    height: 100%;
    flex: 0 1 auto;
    float: left;
    box-sizing: border-box;
    background-color: $themeprimary;
    padding: 20em;
}

.biglogo img {
    object-fit: contain;
    height: 100%;
    width: 100%;
    flex: 0 1 auto;
}

.logincontainer {
    text-align: center;
}

.logincontainer h1 {
    text-align: center;
}

.logincontainer Button {
    margin: auto;
}

.signupButton {
    background-color: #007bff !important;
    color: white !important;
    border-radius: 8px;
    font-size: 1rem;
    padding: 12px 24px;
    transition: background-color 0.2s ease-in-out;

    &:hover {
        background-color: #0056b3 !important;
    }
}
