/* Modern UI styling for Target Validation results page */

:root {
  --primary-color: #254961;
  --primary-light: #3a6d8e;
  --primary-dark: #113046;
  --primary-color-rgb: 37, 73, 97;  /* RGB values for #254961 */
  --secondary-color: #ffc654;
  --background-light: #f8faff;
  --text-primary: #333333;
  --text-secondary: #6b7280;
  --border-color: #e5e7eb;
  --success-color: #10b981;
  --warning-color: #f59e0b;
  --error-color: #ef4444;
  --card-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --card-radius: 12px;
  --transition-speed: 0.3s;
  
  /* Edge colors */
  --string-edge-color: rgba(17, 48, 70, 0.8);
  --ai-edge-color: rgba(255, 136, 0, 0.8);
  --secondary-color: #f8a812;
  --accent-color: #e63946;
  --text-color: #333333;
  --background-color: #f5f5f5;
}

/* Global container control */
body, html {
  overflow-x: hidden;
  max-width: 100%;
}

/* Section headers */
.titlesection {
  display: flex;
  width: 100%;
  padding: 12px 24px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  border-radius: var(--card-radius);
  background: linear-gradient(90deg, var(--primary-color) 0%, var(--primary-light) 100%);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.titlesection::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 30%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.1));
}

.titlesection p {
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1.2px;
  margin: 0;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.titlesection p::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 24px;
  background-color: var(--secondary-color);
  margin-right: 16px;
  border-radius: 3px;
}

/* Container styling */
.roundedBorderContainer {
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 20px;
  border: 1px solid var(--border-color);
  border-radius: var(--card-radius);
  background-color: white;
  box-shadow: var(--card-shadow);
  transition: box-shadow var(--transition-speed), transform var(--transition-speed);
}

.roundedBorderContainer:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  transform: translateY(-2px);
}

/* Main layout containers */
.selectedPathContainer {
  width: 68%;
  height: 520px;
  margin-left: 2%;
}

.interPathsContainer {
  float: left;
  height: 520px;
  width: 30%;
  position: relative;
  padding: 20px;
}

.infoContainer {
  float: left;
  height: 520px;
  margin-left: 2%;
  padding: 20px;
  width: 30%;
  position: relative;
}

.interPathsContainer p {
  margin-top: 0px;
  margin-bottom: 8px;
}

/* Scrollable path list */
.pathsListContainer {
  overflow-y: auto;
  max-height: 380px;
  padding-right: 10px;
  scrollbar-width: thin;
  scrollbar-color: var(--primary-light) var(--background-light);
}

.pathsListContainer::-webkit-scrollbar {
  width: 6px;
}

.pathsListContainer::-webkit-scrollbar-track {
  background: var(--background-light);
  border-radius: 10px;
}

.pathsListContainer::-webkit-scrollbar-thumb {
  background-color: var(--primary-light);
  border-radius: 10px;
}

/* Path containers */
.smallPathContainer {
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  padding: 10px;
  cursor: pointer;
  transition: all 0.2s;
  height: 240px;
  display: flex;
  flex-direction: column;
}

.smallPathContainer:hover {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.smallPathContainer.selected-path {
  background-color: rgba(var(--primary-color-rgb), 0.1);
  border-left: 3px solid var(--primary-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.smallPathTitle {
  font-weight: bold;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.smallPathGraphContainer {
  flex: 1;
  overflow: hidden;
  border-radius: 4px;
  background-color: #f9f9f9;
}

/* Button styling */
.pathsListContainer Button {
  margin-top: 10px;
  border-radius: 8px;
  text-transform: none;
  font-weight: 500;
  padding: 8px 16px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all var(--transition-speed);
}

/* Custom styles for summary items */
.summary-item {
  background: white;
  border-radius: var(--card-radius);
  padding: 20px;
  box-shadow: var(--card-shadow);
  height: 100%;
  transition: transform var(--transition-speed);
  border-left: 4px solid var(--primary-color);
}

.summary-item:hover {
  transform: translateY(-3px);
}

.summary-item .main-value {
  font-size: 28px;
  font-weight: 600;
  color: var(--primary-dark);
  margin-bottom: 8px;
}

.summary-item .descriptor {
  font-size: 14px;
  color: var(--text-secondary);
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

/* Target summary item special styling */
.target-summary-item {
  background: linear-gradient(135deg, #f6f9fc 0%, #edf7ff 100%);
  border-left: 4px solid var(--success-color);
}

/* Score summary item special styling */
.score-summary-item {
  background: linear-gradient(135deg, #f6fcf9 0%, #ecf8f3 100%);
  border-left: 4px solid var(--success-color);
}

/* Disease summary item special styling */
.disease-summary-item {
  background: linear-gradient(135deg, #fff7f7 0%, #fef8ec 100%);
  border-left: 4px solid var(--warning-color);
}

/* Custom styles for the top overlay */
.project-details-card {
  background: white;
  border-radius: var(--card-radius);
  padding: 16px 24px;
  box-shadow: var(--card-shadow);
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 3px solid var(--primary-color);
}

.project-details-card .project-title {
  font-size: 22px;
  font-weight: 600;
  color: var(--primary-dark);
  margin-bottom: 4px;
}

.project-details-card .project-subtitle {
  font-size: 14px;
  color: var(--text-secondary);
}

.project-details-tag {
  display: inline-flex;
  align-items: center;
  padding: 4px 12px;
  border-radius: 50px;
  background-color: rgba(37, 73, 97, 0.1);
  color: var(--primary-color);
  font-size: 13px;
  font-weight: 500;
  margin-right: 8px;
}

/* Action buttons */
.action-button {
  border-radius: 8px;
  padding: 10px 20px;
  font-weight: 500;
  text-transform: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.2s;
}

.action-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.primary-action-button {
  background: linear-gradient(90deg, var(--primary-color) 0%, var(--primary-light) 100%);
  color: white;
}

.secondary-action-button {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .selectedPathContainer {
    width: 60%;
  }
  
  .interPathsContainer {
    width: 38%;
  }
}

@media (max-width: 900px) {
  .interPathsContainer, 
  .selectedPathContainer {
    width: 100%;
    margin: 0 0 20px 0;
    float: none;
  }
}

/* Legend styles */
.edge-line {
  display: inline-block;
  width: 20px;
  height: 2px;
  margin-right: 4px;
  vertical-align: middle;
}

.string-edge {
  background-color: var(--string-edge-color);
}

.ai-edge {
  background: repeating-linear-gradient(
    to right,
    var(--ai-edge-color),
    var(--ai-edge-color) 3px,
    transparent 3px,
    transparent 6px
  );
}

