@import '../../_css//globals.scss';

.mydrawer {
    background-color: $themeprimary;
}

.topcontainer {
    height: 100px;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
}

.topcontainer img {
  object-fit: scale-down;
  height: 100%;
  width: 100%;
/* or
  object-fit: cover; */
} 
