.footer-logo {
  height: 20px;
}

.footer-logo-text {
  height: 15px;
  padding-bottom: 1px;
}

.footer-text {
  color: white;
  font-family: Open Sans, Arial, sans-serif;
}

html,
body,
#root {
  height: 100%
}

#root {
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 0 auto;
  padding-bottom: 20px;
}

.footer {
  flex-shrink: 0;
}