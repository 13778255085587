.notepadArea {
    width: 40%;
    position: fixed;
    top: 20px;
    z-index: 10;
    margin: 10px;
}

.notepadFrame {
    position: relative;
    margin-top: 5px;
    padding: 13px;
}

.noteFrame {
    margin: 5px;
    padding: 5px;
}

.noteFrameBottom {
    margin-top: 5px;
}
