/**
 * SEARCH FIELD:
 * *************
 */
.search-wrapper {
  position: relative;
}

.search-wrapper>input[type="search"] {
  width: calc(100%);
  height: 2.5em;
  box-shadow: var(--shadow);
  border: none;
  outline: none;
  border-radius: var(--border-radius);
  margin-bottom: 0.5em;
  padding: 1em 0.5em 0.5em 3em;
  font-family: Lato, sans-serif;
  font-size: 1em;
}

.search-wrapper>.icon {
  position: absolute;
  width: 1em;
  height: 1em;
  top: 1em;
  left: 1em;
}

.noBorderControlsContainer {
  border: none;
}