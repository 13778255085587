
.filter_icon_with_value {
    animation: heartbeatAnimation 2s infinite linear; /* Adjust animation properties as needed */
}
  
@keyframes heartbeatAnimation {
    0% {
        transform: scale(1);
    }
    20% {
        transform: scale(1);
    }
    40% {
        transform: scale(1.5);
    }
    60% {
        transform: scale(1);
    }
    70% {
        transform: scale(1.5);
    }
    80% {
        transform: scale(1);
    }
    100% {
        transform: scale(1);
    }
}