.loader {
    /* this centers the loader */
    position: absolute; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.dna-loader {
    position: relative;
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dna-container {
    width: 100%;
    height: 100px;
    position: relative;
    perspective: 800px;
    animation: rotate 4s linear infinite;
    transform-style: preserve-3d;
}

.dna-rung {
    position: absolute;
    width: 80px;
    height: 10px;
    left: 50%;
    margin-left: -40px;
    transform-style: preserve-3d;
}

/* Position each rung at different heights */
.dna-rung:nth-child(1) { top: 0%; }
.dna-rung:nth-child(2) { top: 10%; }
.dna-rung:nth-child(3) { top: 20%; }
.dna-rung:nth-child(4) { top: 30%; }
.dna-rung:nth-child(5) { top: 40%; }
.dna-rung:nth-child(6) { top: 50%; }
.dna-rung:nth-child(7) { top: 60%; }
.dna-rung:nth-child(8) { top: 70%; }
.dna-rung:nth-child(9) { top: 80%; }
.dna-rung:nth-child(10) { top: 90%; }

/* Offset the rotation of each rung to create the helix effect */
.dna-rung:nth-child(1) { transform: rotateY(0deg); }
.dna-rung:nth-child(2) { transform: rotateY(36deg); }
.dna-rung:nth-child(3) { transform: rotateY(72deg); }
.dna-rung:nth-child(4) { transform: rotateY(108deg); }
.dna-rung:nth-child(5) { transform: rotateY(144deg); }
.dna-rung:nth-child(6) { transform: rotateY(180deg); }
.dna-rung:nth-child(7) { transform: rotateY(216deg); }
.dna-rung:nth-child(8) { transform: rotateY(252deg); }
.dna-rung:nth-child(9) { transform: rotateY(288deg); }
.dna-rung:nth-child(10) { transform: rotateY(324deg); }

/* Create the left and right strands */
.dna-left, .dna-right {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    top: 50%;
    margin-top: -4px;
}

.dna-left {
    left: 0;
    background-color: #113046; /* Primary main (dark blue) */
    box-shadow: 0 0 5px rgba(17, 48, 70, 0.7);
}

.dna-right {
    right: 0;
    background-color: #FECE61; /* Secondary main (orange) */
    box-shadow: 0 0 5px rgba(254, 206, 97, 0.7);
}

/* Animation to rotate the DNA */
@keyframes rotate {
    0% {
        transform: rotateX(0deg);
    }
    100% {
        transform: rotateX(360deg);
    }
}

/* Add pulsation effect to the nucleotides */
.dna-left, .dna-right {
    animation: pulse 2s ease-in-out infinite alternate;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.2);
        opacity: 0.8;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
